import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Control, Controller } from 'react-hook-form';

interface Props {
  name: string;
  control: Control<any, any>;
  options: { value: string; label: string }[];
  defaultValue?: string;
}

/**
 * Renders a full-width Check component
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param options list of options in {value, label} form
 */
const RadioComponent: React.FC<Props> = ({ name, control, options, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup {...field} style={{ width: '100%', marginBottom: '16px', marginTop: '0px' }}>
          {options.map((option) => {
            return (
              <FormControlLabel
                key={`form-control-option-${option.value}`}
                value={option.value}
                control={<Radio style={{ paddingTop: '2px', paddingBottom: '2px' }} color="secondary" />}
                label={<Typography variant="body2">{option.label}</Typography>}
              />
            );
          })}
        </RadioGroup>
      )}
    />
  );
};

export default RadioComponent;
