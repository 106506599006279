import React from 'react';
import { Control } from 'react-hook-form';
import DateComponent from '/components/form/Date';

interface Props {
  control: Control<any, any>;
  name?: string;
  label?: string;
}

const DateOfArrivalInput: React.FC<Props> = ({ control, name, label }) => {
  return (
    <DateComponent name={name || 'arrivalDate'} control={control} label={label || 'Arrival to Stockholm'} noValidate />
  );
};

export default DateOfArrivalInput;
